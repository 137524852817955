/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactPageLayout from '../components/contactPageLayout';
import PropTypes from 'prop-types';
import TopMenu from '../components/topMenu';



const FunPerson = ({ color }) => {    
    const subtitleContent = (
        
    <div className="text-md">
      <p>
      Ready to live a vibrant healthy life? Want to fire your boss and travel the world while making new friends all while working from a coffee shop or the beach? Connect with us and we’ll start you on the plan to grow your health while traveling and having fun! What are you waiting for?! Vibe with us!
      </p>
    </div>
  );
    
  return (
    <Layout to="/connect" label="WHICH IMAGE?" showMenuIcon>
      <SEO title="Vivo Vibe" />
                                      <TopMenu></TopMenu>

                  <section className="h-screen w-screen blue-bg-img">

      <ContactPageLayout
        color={color}
        title="Who’s ready to Vibe?"
        titleClassName="bg-directions"
                subTitle={subtitleContent}
        subtitleContent={subtitleContent}
      ></ContactPageLayout>
      </section>
      </Layout>
  );
};

FunPerson.propTypes = {
  color: PropTypes.string,
};

FunPerson.defaultProps = {
  color: 'winner',
};
export default FunPerson;
